import React from 'react';
import { BrowserRouter as Router, Route, Routes, useSearchParams} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import OtpPage from './Otp/OtpPage';
import VerificationSuccessful from './VerificationSuccessful/VerificationSuccessful'
import ProtectedRoute from './protectedRoute';
import { AuthProvider } from './Context/statusContext';
import Unauth from './Pages/unauthorised';
import Login from './Pages/LoginPage';
import NewUsersTable from './admin/NewUsers';
import EventListPage from './admin/eventList';
import AdminLogin from './admin/adminLogin/LoginPage';
const AppWithQueryParams = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("eventId"); 
  return <App eventId={eventId} />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    
    <Router>
    <AuthProvider>
      <Routes>
        <Route path = "/unauthorized" element={< Unauth />}/>
        {/* <Route path="/" element={<ProtectedRoute component={OtpPage} allowedStatuses={0} />} /> */}
        <Route 
            path="/register" 
            element={<ProtectedRoute component={AppWithQueryParams  } allowedStatuses={[0]} />} 
        />
        <Route 
            path="/otp" 
            element={<ProtectedRoute component={OtpPage  } allowedStatuses={[1]} />} 
        />
        {/* <Route path="/otp" element={<OtpPage />} /> */}
        <Route 
            path="/verification-successful" 
            element={<ProtectedRoute component={VerificationSuccessful  } allowedStatuses={[2]} />} 
        />
        <Route path = "/login" element = {<Login/>}/>

        <Route 
            path="/admin/events" 
            element={<ProtectedRoute component={EventListPage  } allowedStatuses={[100]} />} 
        />
        <Route 
            path="/admin/newUsers/:eventId" 
            element={<ProtectedRoute component={NewUsersTable  } allowedStatuses={[100]} />} 
        />
        {/* <Route path="admin/events" element={<EventListPage />} /> */}
        {/* <Route path = "/admin/newUsers/:eventId" element = {<NewUsersTable/>}/> */}
        <Route path = "/admin" element = {<AdminLogin/>}/>
        
        {/* <Route path="/verification-successful" element={<VerificationSuccessful />} /> */}
      </Routes>
      </AuthProvider>
    </Router>

);

reportWebVitals();