import React, { useEffect, useState } from 'react';
import './VerificationSuccessful.css';
import logo from '../samply_logo.png'; 
import UserService from '../services/Register';
import Cookies from 'js-cookie';
const VerificationSuccessful = () => {
    const [verified, setIsVerified] = useState(null); 
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkVerificationStatus = async () => {
            try {
                const otpResponse = await UserService.verificationCheck();
                console.log(otpResponse);

                if (otpResponse.status === 200 && otpResponse.data && otpResponse.data.verified) {
                    setIsVerified(true); 
                } else {
                    setIsVerified(false); 
                }
            } catch (error) {
                console.error("Error during verification check:", error);
                setIsVerified(false); 
            } finally {
                setLoading(false); 
            }
        };
        checkVerificationStatus();
    }, []);

    // Logout function
    const handleLogout = () => {
        Cookies.remove("accessToken")
        window.location.href = '/login'; // Redirect to login page
    };

    // Loading spinner UI
    if (loading) {
        return (
            <div className="verification-success-container">
                <img src={logo} alt="Logo" className="logo" />
                <div className="loader">Loading...</div>
            </div>
        );
    }

    // Verified UI
    if (verified) {
        return (
            <div className="verification-success-container">
                <img src={logo} alt="Logo" className="logo" />
                <div className="success-icon">
                    <i className="fas fa-check-circle"></i>
                </div>
                <h2>Success</h2>
                <p>Congratulations, your account has been successfully registered and verified!</p>
                <button className="logout-button" onClick={handleLogout}>
                    Logout
                </button>
            </div>
        );
    }

    // Not Verified UI
    return (
        <div className="verification-failed-container">
            <img src={logo} alt="Logo" className="logo" />
            <div className="error-icon">
                <i className="fas fa-times-circle"></i>
            </div>
            <h2>Verification Failed</h2>
            <p>We're sorry, but your account could not be verified. Please try again later or contact support.</p>
        </div>
    );
};

export default VerificationSuccessful;
