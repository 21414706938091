import React, { useState, useEffect } from "react";
import axios from "axios";
import "./NewUserTable.css"; // Import the CSS file
import logo from "../samply_logo.png"; // Import the logo
import { useParams } from "react-router-dom";

const NewUsersTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { eventId } = useParams();
  const [totalUsers, setTotalUsers] = useState(0);

  const fetchUsers = () => {
    setLoading(true);
    axios
      .post("https://api.samply.in/api/users/new-users", { eventId: eventId })
      .then((response) => {
        setUsers(response.data.users);
        setTotalUsers(response.data.total_users); // Setting total users
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to fetch users.");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUsers(); // Initial fetch when component mounts
  }, [eventId]); // Fetch new data if eventId changes

  // Function to mark offer as availed
  const markOfferAvailed = (id) => {
    axios
      .post("https://api.samply.in/api/users/mark/offerAvailed", {
        userId: id,
      })
      .then(() => {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === id ? { ...user, offer_availed: true } : user
          )
        );
      })
      .catch((err) => {
        setError("Failed to mark offer as availed.");
      });
  };

  return (
    <div className="container">
      <img src={logo} alt="Logo" className="logo" /> {/* Logo at the top */}
      <h2 className="heading">Newly Registered</h2>

      {/* Display total users */}
      <div className="total-users">
        <h3>Total Users Registered: {totalUsers}</h3>
      </div>

      {/* Refresh Button */}
      <button className="refresh-button" onClick={fetchUsers}>
        Refresh List
      </button>

      {loading && <div className="spinner"></div>}
      {error && <div className="error-message">{error}</div>}
      {!loading && users.length === 0 && <div className="no-users">No new users found</div>}

      {!loading && users.length > 0 && (
        <div className="table-container">
          <table className="user-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Offer Availed</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.phone}</td>
                  <td>{user.offer_availed ? "Yes" : "No"}</td>
                  <td>
                    {!user.offer_availed && (
                      <button
                        className="mark-button"
                        onClick={() => markOfferAvailed(user.id)}
                      >
                        Mark as Availed
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default NewUsersTable;
