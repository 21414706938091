import { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import UserService from '../services/Register';



const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const hasRedirected = useRef(false);

  const STATUS_ROUTES = {
    100:"/admin/events",
    0: '/register',
    1: '/otp',
    2: '/verification-successful',
  };

  useEffect(() => {
    const checkStatusAndRedirect = async () => {
      // if (hasRedirected.current) return; 
      
      try {
        const response = await UserService.statusTrack();
        const parsedStatus = response.data.statusTrack;
        setStatus(parsedStatus);

        console.log(STATUS_ROUTES[parsedStatus]);
        if (parsedStatus !== 0 && STATUS_ROUTES[parsedStatus] !== location.pathname) {
          console.log('routing');

          if(!location.pathname.includes("/admin") && parsedStatus==100)
          {
            navigate(STATUS_ROUTES[parsedStatus], { replace: true });
          }
          if(parsedStatus!=100 && parsedStatus != 0)
          {
            navigate(STATUS_ROUTES[parsedStatus], { replace: true });
          }
          
          
          
        }
      } catch (error) {
        console.error('Status check failed:', error);
        setStatus(0);
      } finally {
        setLoading(false);
      }
    };

    checkStatusAndRedirect();
  }, [navigate]);

  return (
    <AuthContext.Provider value={{ status, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
