import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBInput } from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';
import UserService from '../../services/Register';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import Cookies from 'js-cookie';

const AdminLogin = () => {
    const [phone, setPhone] = useState('+91'); 
    const [phoneDigits, setPhoneDigits] = useState(''); 
    const [exist,setIsExist] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false); 
    const navigate = useNavigate();

    useEffect(()=>{
        const isExist = Cookies.get('accessToken') ? true : false
        console.log(isExist);
        if(isExist){
            navigate("/otp",{replace:true});
        }
    },[])

    const handlePhoneChange = (e) => {
        let value = e.target.value;


        if (/^\d{0,10}$/.test(value)) { 
            setPhoneDigits(value);
        }
    };

    const validatePhoneNumber = (number) => {
        const regex = /^\+91\d{10}$/;
        return regex.test(number);
    };

    const checkPhone = async() => {
        // const response = await UserService.verifyPhone(phone+phoneDigits)
        // if(response.status == 200){
        //     if(!response.data.isNew){
        try{
            const response = await UserService.adminLoginSendOtp(phone+phoneDigits);
                if(response.status == 200)
                {
                    console.log("gag")
                    setIsExist(true);
                    setOtpSent(true);
                }
               
                    
        }
        catch(error)
        {
            toast.error('Something went Wrong');
        }
                
                
                
            }
        // }
        
    // }

    const Sendotp = async () => {
        const fullPhone = phone + phoneDigits; 

        if (!validatePhoneNumber(fullPhone)) {
            toast.error('Please enter a valid phone number in the format +91 xxxxxxxxxx');
            return;
        }

        try {
            const response = await UserService.statusTrack();
            if (response.data.phone) {
                setPhone(response.data.phone);
            }
            if (!otpSent) {
                const otpResponse = await UserService.sendOtp(fullPhone);
                if (otpResponse.status === 200) {
                    setOtpSent(true); 
                    toast.success(otpResponse.data.message || 'OTP sent successfully!');
                }
            }
        } catch (error) {
            console.error('Error fetching phone or sending OTP:', error);
            toast.error('Error occurred while sending OTP.');
        }
    };

    const handleOtpSubmit = async () => {
        if (!otp) {
            toast.error('Please enter the OTP');
            return;
        }

        const fullPhone = phone + phoneDigits; 
        try {
            const response = await UserService.adminLoginValidateOtp({ otp, phone: fullPhone });
            if (response.status == 200) {
                // if(response.data.acess_token && response.data.refresh_token){
                    
                    // localStorage.setItem('accessToken', response.data.acess_token);   
                    // localStorage.setItem('refreshToken', response.data.refresh_token);
                    toast.success(response.data.message || 'OTP validated! Redirecting...');
                    navigate('/admin/events', { replace: true });
                // }
                }
            
            }
            catch (error) {
                console.error('Error validating OTP:', error.response?.data || error.message);
                toast.error(error.response?.data.message || 'Failed to validate OTP');
            }
    }
    

    return (
        <div className="otp-page-container">
            <div className="otp-form">
                <h2>OTP Verification</h2>
                {!otpSent && (
                    <>
                        <div className="phone-input-container">
                            <MDBInput
                                label="Country Code"
                                type="text"
                                value={phone}
                                disabled
                                className="phone-prefix-input"
                                style={{ marginBottom: '20px' }}
                            />
                            <MDBInput
                                label="Phone Number"
                                type="text"
                                value={phoneDigits}
                                onChange={handlePhoneChange}
                                maxLength={10}
                                className="phone-digits-input"
                                style={{ marginBottom: '20px' }}
                            />
                        </div>
                        <MDBBtn
                            color="primary"
                            onClick={checkPhone}
                            className="send-otp-btn"
                            disabled={otpSent}
                        >
                            Next
                        </MDBBtn>
                    </>
                )}
                {otpSent && (
                    <>
                        <MDBInput
                            label="OTP"
                            type="text"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            className="otp-input"
                        />
                        <MDBBtn
                            color="warning"
                            onClick={handleOtpSubmit}
                            className="ms-2"
                        >
                            Submit
                        </MDBBtn>
                    </>
                )}
            </div>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

export default AdminLogin;
