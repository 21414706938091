import React from 'react';
import './unauth.css';

const Unauth = () => {
  return (
    <div className="unauthorized-container">
      <div className="unauthorized-content">
        <h1 className="unauthorized-title">Oops! Unauthorized Access</h1>
        <p className="unauthorized-message">
          You don't have permission to view this page. Please check your access or try scanning the QR again!
        </p>
      </div>
    </div>
  );
};

export default Unauth;
