import { useEffect, useContext } from "react";
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from "./Context/statusContext";
import { ClipLoader } from 'react-spinners';
import Unauth from "./Pages/unauthorised";

const ProtectedRoute = ({ component: Component, allowedStatuses, redirectPath = '/unauthorized' }) => {
  const { status, loading } = useAuth();
  const location = useLocation();

  // useEffect(() => {
  //   console.log(`ProtectedRoute: Current status = ${status}`);
  // }, [status]);

  if (loading) {
    return (
    <div className="flex items-center justify-center min-h-screen">
      <ClipLoader color="#3498db" loading={loading} size={60} />
    </div>
    );
  }

  const statusArray = Array.isArray(allowedStatuses) ? allowedStatuses : [allowedStatuses];
  console.log(statusArray);
  console.log("status",status);
  console.log(location);
  
  if (!statusArray.includes(status)) {
       return (
      <div className="flex items-center justify-center min-h-screen">
        <ClipLoader color="#3498db" loading={loading} size={60} />
      </div>
    );
  }

  if (allowedStatuses.includes(status)) {
    return <Component />;
  }

  // return <Component />;
};

export default ProtectedRoute;
