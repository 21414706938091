import axios from 'axios';
import Cookies from 'js-cookie';

const URL = 'https://api.samply.in/api';
// const URL = 'http://localhost:8000/api';

const AxiosInstance = () => {
  const instance = axios.create({
    baseURL: URL,
    timeout: 5000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

  // Request interceptor to add the Authorization header dynamically
  instance.interceptors.request.use((config) => {
    const token = Cookies.get('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // Response interceptor for global error handling
  instance.interceptors.response.use((response) => response, (error) => {
    // Handle unauthorized errors globally
    if (error.response && error.response.status === 401) {
      // localStorage.clear();
      // window.location.href = '/login';
    }
    return Promise.reject(error);
  });

  return instance;
};

export default AxiosInstance;
