import React, { useState, useEffect } from 'react';
import { MDBBtn, MDBInput } from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';
import UserService from '../services/Register';
import { useNavigate } from 'react-router-dom';
import '../Otp/OtpPage.css';

const OtpPage = () => {
    const [otp, setOtp] = useState('');
    const [phone, setPhone] = useState('');
    const [otpTimer, setOtpTimer] = useState(0); // Start with 0 timer for OTP expiration
    const [resendTimer, setResendTimer] = useState(0); // Start with 0 timer for resend button
    const [otpSent, setOtpSent] = useState(false); // Flag to prevent multiple OTP sends
    const navigate = useNavigate();

    useEffect(() => {
        // Start the timers only after OTP is sent
        let otpCountdown;
        let resendCountdown;

        if (otpSent) {
            // Countdown for OTP expiration
            otpCountdown = setInterval(() => {
                setOtpTimer((prev) => {
                    if (prev === 1) {
                        clearInterval(otpCountdown);
                        toast.error('OTP expired. Please resend the OTP.');
                        return 0; // Set timer to 0 and stop
                    }
                    return prev - 1;
                });
            }, 1000);

            // Countdown for resend OTP timer
            resendCountdown = setInterval(() => {
                setResendTimer((prev) => {
                    if (prev === 1) {
                        clearInterval(resendCountdown);
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        // Clean up timers when the component is unmounted or OTP is not sent
        return () => {
            clearInterval(otpCountdown);
            clearInterval(resendCountdown);
        };
    }, [otpSent]); // Run effect only when otpSent changes

    const Sendotp = async () => {
        try {
            const response = await UserService.statusTrack();
            if (response.data.phone) {
                setPhone(response.data.phone);
            }
            if (!otpSent && response.data.phone) {
                const otpResponse = await UserService.sendOtp(response.data.phone);
                if (otpResponse.status === 200) {
                    setOtpSent(true); // OTP sent successfully, enable countdown
                    setOtpTimer(60); // Start OTP expiration timer
                    setResendTimer(30); // Start resend OTP timer
                    toast.success(otpResponse.data.message || 'OTP sent successfully!');
                }
            }
        } catch (error) {
            console.error('Error fetching phone or sending OTP:', error);
            toast.error('Error occurred while sending OTP.');
        }
    };

    const handleOtpSubmit = async () => {
        if (!otp) {
            toast.error('Please enter the OTP');
            return;
        }

        try {
            const response = await UserService.validateOtp({ otp, phone });
            if (response.data.type === 'success') {
                toast.success(response.data.message || 'OTP validated! Redirecting...');
                navigate('/verification-successful', { replace: true });
            } else {
                toast.error(response.data.message || 'OTP did not match. Please try again.');
            }
        } catch (error) {
            console.error('Error validating OTP:', error.response?.data || error.message);
            toast.error(error.response?.data.message || 'Failed to validate OTP');
        }
    };

    const handleResendOtp = async () => {
        if (resendTimer > 0) return; // Don't resend OTP while countdown is active

        try {
            if (!phone) {
                throw new Error('Phone number is missing. Please log in again.');
            }
            const otpResponse = await UserService.resendOtp(phone);
            toast.success(otpResponse.data.message || 'OTP resent successfully!');
            setOtpTimer(60); // Reset OTP expiration timer
            setResendTimer(30); // Reset resend OTP timer
        } catch (error) {
            console.error('Error resending OTP:', error.response?.data || error.message);
            toast.error(error.response?.data.message || 'Failed to resend OTP');
        }
    };

    return (
        <div className="otp-page-container">
            <div className="otp-form">
                <h2>OTP Verification</h2>
                {/* Show OTP input only after OTP is sent */}
                {otpSent && (
                    <>
                        <p className="otp-timer">OTP expires in {otpTimer} seconds</p>
                        <MDBInput
                            label="OTP"
                            type="text"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            className="otp-input"
                        />
                    </>
                )}
                <div className="btn-container">
                    {!otpSent ? (
                        <MDBBtn
                            color="primary"
                            onClick={Sendotp}
                            disabled={otpSent} // Disable button after OTP is sent
                            className="send-otp-btn"
                        >
                            Send OTP
                        </MDBBtn>
                    ) : (
                        <>
                            <MDBBtn
                                color="warning"
                                onClick={handleOtpSubmit}
                                className="ms-2"
                            >
                                Submit
                            </MDBBtn>
                            <MDBBtn
                                color="secondary"
                                onClick={handleResendOtp}
                                className="ms-2"
                                disabled={resendTimer > 0} // Disable resend button during countdown
                            >
                                {resendTimer > 0 ? `Resend OTP in ${resendTimer} seconds` : 'Resend OTP'}
                            </MDBBtn>
                        </>
                    )}
                </div>
            </div>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
};

export default OtpPage;
